import helpers from "../components/helpers/main";

export const Footer = () => {
  const rotation = 360;
  const step = 4;
  const numberToRotate = 2;
  const rotatingTiles = [];
  const rotatingFinalTiles = [];
  let tiles = [];
  let finalTiles = [];

  const siteFooter = document.querySelector(".footer-section");
  if (siteFooter) {
    helpers.addActiveOnScroll(siteFooter);

    const footerSvg = siteFooter.querySelector(".site-footer__svg");
    const purpleLine = siteFooter.querySelector(".purple-line");
    const purpleLinePath = purpleLine.querySelector(".purple-line__path");
    const square_animate = siteFooter.querySelectorAll(".square_animate");
    const top_animate = siteFooter.querySelectorAll(".top_animate");
    const bottom_animate = siteFooter.querySelectorAll(".bottom_animate");

    console.log("square_animate", square_animate);

    let maxTime = 0;

    let prevState = siteFooter.classList.contains("active");
    const classObserver = new MutationObserver((mutations) => {
      mutations.forEach((mu) => {
        if (mu.attributeName === "class") {
          const currentState = mu.target.classList.contains("active");
          if (prevState !== currentState) {
            prevState = currentState;

            const selectRandomTile = () => {
              const index = Math.floor(Math.random() * (tiles.length - 0) + 0);
              const placeholder = tiles.splice(index, 1);
              rotatingTiles.push(placeholder[0]);
            };

            const randomIntFromInterval = (min, max) => {
              // min and max included
              return Math.floor(Math.random() * (max - min + 1) + min);
            };

            const rotateTile = (tile, min, max, delay = 0) => {
              const tileBox = tile.getBBox();
              const tileX = tileBox.x + tileBox.width / 2;
              const tileY = tileBox.y + tileBox.height / 2;
              const steps = randomIntFromInterval(min, max);

              if (steps > maxTime) {
                maxTime = steps;
              }

              tile.setAttribute(
                "transform",
                `rotate(${steps * (rotation / step)} ${tileX} ${tileY})`
              );

              const element = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "animateTransform"
              );
              element.setAttribute("attributeName", "transform");
              element.setAttribute("attributeType", "XML");
              element.setAttribute("type", "rotate");
              element.setAttribute(
                "from",
                `${steps * (rotation / step)} ${tileX} ${tileY}`
              );
              element.setAttribute("to", `0 ${tileX} ${tileY}`);
              element.setAttribute("dur", `${steps}s`);
              element.setAttribute("repeatCount", "1");
              element.setAttribute("fill", "freeze");

              setTimeout(() => {
                tile.appendChild(element);
                const animation = tile.querySelector("animateTransform");
                animation.beginElement();
              }, delay);
            };

            tiles = Array.from(footerSvg.querySelectorAll(".tile"));

            for (let i = 0; i < numberToRotate; i++) {
              if (tiles.length > 0) {
                selectRandomTile();
              } else {
                break;
              }
            }

            rotatingTiles.forEach((tile, tileIndex) => {
              rotateTile(tile, 1, 1);
            });

            setTimeout(() => {
              purpleLinePath.classList.add("active");
              setTimeout(() => {
                square_animate.forEach((animate) => {
                  animate.beginElement();
                });
              }, 3000);
              setTimeout(() => {
                top_animate.forEach((animate) => {
                  animate.beginElement();
                });
              }, 3279.649167408161);
              setTimeout(() => {
                bottom_animate.forEach((animate) => {
                  animate.beginElement();
                });
              }, 3400.406762425321);
            }, maxTime * 1000);
          }
        }
      });
    });

    classObserver.observe(siteFooter, { attributes: true });
  }
};

export default Footer;
