export const SoftwareMenu = () => {
  const $softwareMenu = document.querySelector(".software-menu");

  const $navbar = document.querySelector(".site-header .navbar");

  if ($softwareMenu) {
    const colour = $softwareMenu.dataset.colourClass;
    const $menuItems = $softwareMenu.querySelectorAll(".software-menu__item");
    $menuItems.forEach(($menuItem) => {
      $menuItem.addEventListener("click", (event) => {
        event.preventDefault();
        $menuItems.forEach(($menuItemInner) => {
          if (event.target === $menuItemInner) {
            $menuItemInner.classList.add(colour);
          } else {
            $menuItemInner.classList.remove(colour);
          }
        });
        const $section = document.querySelector($menuItem.dataset.section);
        if ($section) {
          const y =
            $section.offsetTop -
            $softwareMenu.offsetHeight -
            $navbar.offsetHeight;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      });
    });
  }
};

export default SoftwareMenu;
