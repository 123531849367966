export const logoAnimation = {
  v: "5.7.13",
  fr: 30,
  ip: 0,
  op: 301,
  w: 600,
  h: 270,
  nm: "HWC_Logo_Anim 03",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "Null 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 40,
              s: [-180],
            },
            { t: 56, s: [-90] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [512.5, 93.75, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 40,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 48,
              s: [95, 95, 100],
            },
            { t: 56, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 10,
      op: 910,
      st: 10,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "hwc Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [236.851, 167.982, 0], ix: 2, l: 2 },
        a: { a: 0, k: [234.113, 93.359, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [16.113, 0],
                    [9.837, 5.597],
                    [5.766, 10.006],
                    [0, 12.89],
                    [-5.597, 10.006],
                    [-9.837, 5.596],
                    [-12.889, 0],
                    [-11.024, -8.48],
                    [-3.053, -14.755],
                    [0, 0],
                    [5.087, 3.392],
                    [6.785, 0],
                    [6.277, -6.783],
                    [0, -12.041],
                    [-6.274, -6.784],
                    [-8.99, 0],
                    [-4.919, 3.393],
                    [-1.696, 6.275],
                    [0, 0],
                    [11.022, -8.649],
                  ],
                  o: [
                    [-12.889, 0],
                    [-9.837, -5.596],
                    [-5.597, -10.006],
                    [0, -12.889],
                    [5.766, -10.006],
                    [9.837, -5.597],
                    [16.113, 0],
                    [11.022, 8.31],
                    [0, 0],
                    [-1.696, -6.105],
                    [-4.919, -3.562],
                    [-8.99, 0],
                    [-6.274, 6.784],
                    [0, 12.042],
                    [6.277, 6.784],
                    [6.785, 0],
                    [5.087, -3.392],
                    [0, 0],
                    [-3.053, 14.246],
                    [-11.024, 8.652],
                  ],
                  v: [
                    [172.044, 93.109],
                    [137.956, 84.715],
                    [114.551, 61.31],
                    [106.157, 26.966],
                    [114.551, -7.377],
                    [137.956, -30.781],
                    [172.044, -39.176],
                    [212.749, -26.457],
                    [233.863, 8.141],
                    [199.52, 8.141],
                    [189.344, -6.105],
                    [171.791, -11.448],
                    [148.894, -1.272],
                    [139.482, 26.966],
                    [148.894, 55.204],
                    [171.791, 65.381],
                    [189.344, 60.292],
                    [199.52, 45.792],
                    [233.863, 45.792],
                    [212.749, 80.134],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-59.501, 90.057],
                    [-96.388, -36.124],
                    [-64.08, -36.124],
                    [-42.202, 54.696],
                    [-16.762, -36.124],
                    [19.361, -36.124],
                    [44.802, 54.696],
                    [66.934, -36.124],
                    [99.243, -36.124],
                    [62.1, 90.057],
                    [28.265, 90.057],
                    [1.3, -4.324],
                    [-25.666, 90.057],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-7.123, 3.901],
                    [-9.327, 0],
                    [-8.48, -9.836],
                    [0, -18.995],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [4.41, 5.766],
                    [9.327, 0],
                    [6.106, -6.444],
                    [0, -11.532],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [4.24, -6.953],
                    [7.292, -4.07],
                    [15.603, 0],
                    [8.649, 9.837],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -10.854],
                    [-4.24, -5.767],
                    [-9.159, 0],
                    [-5.936, 6.445],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-233.863, 90.057],
                    [-233.863, -93.109],
                    [-201.301, -93.109],
                    [-201.301, -16.79],
                    [-184.256, -33.071],
                    [-159.326, -39.176],
                    [-123.201, -24.422],
                    [-110.227, 18.826],
                    [-110.227, 90.057],
                    [-142.535, 90.057],
                    [-142.535, 21.878],
                    [-149.15, -3.052],
                    [-169.501, -11.702],
                    [-192.397, -2.035],
                    [-201.301, 24.931],
                    [-201.301, 90.057],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 3",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [234.113, 93.359], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 5,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 910,
      st: 10,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Top Curve",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 21,
              s: [97.75, 3.333, 0],
              to: [-0.506, 0.506, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 26,
              s: [94.712, 6.371, 0],
              to: [0, 0, 0],
              ti: [-0.506, 0.506, 0],
            },
            { t: 31, s: [97.75, 3.333, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 36.91],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [36.91, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-36.908, 36.908],
                    [-29.923, 36.908],
                    [36.908, -29.923],
                    [36.908, -36.908],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.254901960784, 0.411764705882, 0.960784313725, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13.36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 5,
                s: [100],
              },
              { t: 15, s: [0] },
            ],
            ix: 1,
          },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 5,
      op: 905,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Top Angle 2",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 21,
              s: [97.591, 96.566, 0],
              to: [-0.506, -0.506, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 26,
              s: [94.553, 93.528, 0],
              to: [0, 0, 0],
              ti: [-0.506, -0.506, 0],
            },
            { t: 31, s: [97.591, 96.566, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [259.316, -88.841, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-36.907, -36.908],
                    [-36.907, 36.908],
                    [36.907, 36.908],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.254901960784, 0.411764705882, 0.960784313725, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13.36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [259.316, -88.841], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 43.2,
                s: [50],
              },
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 45,
                s: [54.532],
              },
              { t: 49.2001953125, s: [100] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 43.2,
                s: [50],
              },
              { t: 49.2001953125, s: [0] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 38,
      op: 934,
      st: 34,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Top Angle",
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [259.316, -88.841, 0], ix: 2, l: 2 },
        a: { a: 0, k: [259.316, -88.841, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-36.907, -36.908],
                    [-36.907, 36.908],
                    [36.907, 36.908],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.007843137255, 0.364705882353, 0.38431372549, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13.36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [259.316, -88.841], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 11,
                s: [50],
              },
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 14,
                s: [54.532],
              },
              { t: 21, s: [100] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 11,
                s: [50],
              },
              { t: 21, s: [0] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 11,
      op: 55,
      st: 3,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Bottom Angle 2",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 270, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 21,
              s: [93.25, 262.75, 0],
              to: [0.507, 0.507, 0],
              ti: [0.083, 0.083, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 26,
              s: [96.294, 265.794, 0],
              to: [-0.083, -0.083, 0],
              ti: [0.591, 0.591, 0],
            },
            { t: 31, s: [92.75, 262.25, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-36.907, -36.908],
                    [-36.907, 36.908],
                    [36.907, 36.908],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.254901960784, 0.411764705882, 0.960784313725, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13.36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [259.316, -88.841], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 43.2,
                s: [50],
              },
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 45,
                s: [54.532],
              },
              { t: 49.2001953125, s: [100] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 43.2,
                s: [50],
              },
              { t: 49.2001953125, s: [0] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 38,
      op: 934,
      st: 34,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Bottom Angle",
      parent: 6,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 360, ix: 10 },
        p: { a: 0, k: [259.316, -88.841, 0], ix: 2, l: 2 },
        a: { a: 0, k: [259.316, -88.841, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-36.907, -36.908],
                    [-36.907, 36.908],
                    [36.907, 36.908],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [1, 0.356862745098, 0.372549019608, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13.36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [259.316, -88.841], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 11,
                s: [50],
              },
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 14,
                s: [54.532],
              },
              { t: 21, s: [100] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 11,
                s: [50],
              },
              { t: 21, s: [0] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 11,
      op: 55,
      st: 3,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "Bottom Curve",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 270, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 21,
              s: [4, 97.083, 0],
              to: [0.591, -0.591, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 26,
              s: [7.544, 93.539, 0],
              to: [0, 0, 0],
              ti: [0.591, -0.591, 0],
            },
            { t: 31, s: [4, 97.083, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 36.91],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [36.91, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-36.908, 36.908],
                    [-29.923, 36.908],
                    [36.908, -29.923],
                    [36.908, -36.908],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.254901960784, 0.411764705882, 0.960784313725, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13.36, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.25], y: [1] },
                o: { x: [0.33], y: [0] },
                t: 5,
                s: [100],
              },
              { t: 15, s: [0] },
            ],
            ix: 1,
          },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 5,
      op: 905,
      st: 5,
      bm: 0,
    },
  ],
  markers: [],
};

export default logoAnimation;
