import { clickModalOpenTriggerEventHandler } from "./event-handlers/click-modal-open-trigger.js";

/**
 * The modal module.
 *
 * @return {void}
 */
export const modal = () => {
  // Add a click event listener to the document body
  document.addEventListener("click", clickModalOpenTriggerEventHandler);
};

// Export the module
export default modal;
