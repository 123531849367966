import helpers from "../../components/helpers/main";
import Swiper from "swiper/swiper-bundle";

export const HomepageStudies = () => {
  const homepageStudies = document.querySelector(".homepage-knowledgehub");
  if (homepageStudies) {
    helpers.addActiveOnScroll(homepageStudies);

    const $swipers = homepageStudies.querySelectorAll(
      ".homepage-knowledgehub__knowledge.homepage-knowledgehub__knowledge--mobile .homepage-knowledgehub__swiper"
    );

    $swipers.forEach(($swiper) => {
      const swiper = new Swiper($swiper, {
        direction: "horizontal",
        // loopAdditionalSlides: 5,
        spaceBetween: 12,
        slidesPerView: 1,
        slideActiveClass: "homepage-knowledgehub__slide--active",
        slideBlankClass: "homepage-knowledgehub__slide--blank",
        slideClass: "homepage-knowledgehub__slide",
        slideDuplicateActiveClass:
          "homepage-knowledgehub__slide-duplicate--active",
        slideDuplicateClass: "homepage-knowledgehub__slide-duplicate",
        slideDuplicateNextClass: "homepage-knowledgehub__slide-duplicate--next",
        slideDuplicatePrevClass: "homepage-knowledgehub__slide-duplicate--prev",
        slideNextClass: "homepage-knowledgehub__slide--next",
        slidePrevClass: "homepage-knowledgehub__slide--prev",
        wrapperClass: "homepage-knowledgehub__wrapper",
      });
    });
  }
};

export default HomepageStudies;
