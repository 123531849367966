/**
 * Event handler to close a modal.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const clickModalCloseTriggerEventHandler = (event) => {
  // Prevent the default action
  event.preventDefault();

  // Set the elements
  const $close = event.currentTarget;
  const $modal = $close.closest(".js-modal");
  const $open = $close.data.open;

  // Check if the modal exists
  if ($modal) {
    // Set the modal classes
    $modal.classList.add("hidden");

    // Set the body classes
    document.body.classList.remove("overflow-hidden");

    // Check if the open trigger exists
    if ($open) {
      $open.focus();
    }
  }
};

// Export the module
export default clickModalCloseTriggerEventHandler;
