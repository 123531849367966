import splitCharacters from "./splitCharacters";

export const animateLetters = (element) => {
  element.forEach((elementTitle, index) => {
    const wordsArray = elementTitle.textContent.split(" ");
    let wordsString = "";

    wordsArray.forEach((string, stringIndex) => {
      const word = splitCharacters(
        string,
        true,
        `<span class="letter">`,
        `</span>`
      );
      wordsString =
        wordsString +
        (stringIndex === 0
          ? `<span class="inline-block">${word}</span>`
          : `<span>&nbsp;</span><span class="inline-block">${word}</span>`);
    });

    elementTitle.innerHTML = wordsString;
  });

  element.forEach((homepageSoftwareTitle, index) => {
    const spans = homepageSoftwareTitle.querySelectorAll("span.letter");
    spans.forEach((span, spanIndex) => {
      span.style.transitionDelay = `${spanIndex * 20}ms`;
    });
  });
};

export default animateLetters;
