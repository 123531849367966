import Swiper from "swiper/swiper-bundle";

export const SoftwareStudies = () => {
  const softwareStudies = document.querySelector(".software-studies");
  if (softwareStudies) {
    const $swipers = softwareStudies.querySelectorAll(
      ".software-studies__list.software-studies__list--mobile .software-studies__list-swiper"
    );

    $swipers.forEach(($swiper) => {
      const swiper = new Swiper($swiper, {
        direction: "horizontal",
        // loopAdditionalSlides: 5,
        spaceBetween: 12,
        slidesPerView: 1,
        slideActiveClass: "software-studies__list-slide--active",
        slideBlankClass: "software-studies__list-slide--blank",
        slideClass: "software-studies__list-slide",
        slideDuplicateActiveClass:
          "software-studies__list-slide-duplicate--active",
        slideDuplicateClass: "software-studies__list-slide-duplicate",
        slideDuplicateNextClass: "software-studies__list-slide-duplicate--next",
        slideDuplicatePrevClass: "software-studies__list-slide-duplicate--prev",
        slideNextClass: "software-studies__list-slide--next",
        slidePrevClass: "software-studies__list-slide--prev",
        wrapperClass: "software-studies__list-wrapper",
      });
    });
  }
};

export default SoftwareStudies;
