const FooterDemo = () => {
  const footerDemo = document.querySelector(".homepage-demo");
  if (footerDemo) {
    const demoForm = footerDemo.querySelector(".footer-demo__form");
    const firstname = demoForm.querySelector(".footer-demo__form-firstname");
    const surname = demoForm.querySelector(".footer-demo__form-surname");
    const company = demoForm.querySelector(".footer-demo__form-company");
    const job = demoForm.querySelector(".footer-demo__form-job");
    const email = demoForm.querySelector(".footer-demo__form-email");
    const phone = demoForm.querySelector(".footer-demo__form-phone");
    const how = demoForm.querySelector(".footer-demo__form-how");
    const byPhone = demoForm.querySelector(".footer-demo__form-by-phone");
    const byEmail = demoForm.querySelector(".footer-demo__form-by-email");
    const submit = demoForm.querySelector(".footer-demo__form-submit");

    const notification = footerDemo.querySelector(".footer-demo__notification");
    const notificationText = notification.querySelector(
      ".footer-demo__notification"
    );

    const key = document.querySelector(".recaptcha_api_site_key");

    demoForm.addEventListener("submit", (event) => {
      event.preventDefault();
      grecaptcha.ready(function () {
        grecaptcha
          .execute(key.value, { action: "submit" })
          .then(function (token) {
            let formData = new FormData();
            formData.append("firstname", firstname.value);
            formData.append("surname", surname.value);
            formData.append("company", company.value);
            formData.append("job", job.value);
            formData.append("email", email.value);
            formData.append("phone", phone.value);
            formData.append("how", how.value);
            formData.append("byPhone", byPhone.checked);
            formData.append("byEmail", byEmail.checked);
            formData.append("token", token);

            fetch("/wp-json/hwc/v1/form", {
              method: "post",
              body: formData,
            })
              .then((response) => response.json())
              .then((json) => {
                if (json.status === "success") {
                  notificationText.innerText =
                    "Thank you for your inquiry, We will contact you soon.";
                } else {
                  notificationText.innerText =
                    "Sorry but an error has happened, please try again later.";
                }
                demoForm.classList.add("hidden");
                notification.classList.remove("hidden");
              });
          });
      });
    });
  }
};

export default FooterDemo;
