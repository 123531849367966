import { helloWorld } from "./functions/hello-world.js";
import { addActiveOnScroll } from "./functions/addActiveOnScroll";
import { animateLetters } from "./functions/animateLetters";
import { splitCharacters } from "./functions/splitCharacters";
import { splitWords } from "./functions/splitWords";
import { getTargetSelectorParent } from "./functions/get-target-selector-parent.js";
import { isTargetSelector } from "./functions/is-target-selector.js";
import { scrollToElement } from "./functions/scroll-to-element.js";
import { trapFocus } from "./functions/trap-focus.js";

export const helper = {
  /**
   * Log hello world to the console.
   *
   * @return {void}
   */
  helloWorld: () => {
    // Log hello world to the console
    helloWorld();
  },

  /**
   * Log hello world to the console.
   *
   * @param  {element}  element     The event target.
   * @param  {bool}  first     The event target.
   * @return {void}
   */
  addActiveOnScroll: (element, first = false) => {
    // Log hello world to the console
    addActiveOnScroll(element, first);
  },

  /**
   * Log hello world to the console.
   *
   * @param  {element}  element     The event target.
   * @param  {bool}  first     The event target.
   * @return {void}
   */
  animateLetters: (element) => {
    // Log hello world to the console
    animateLetters(element);
  },
  /**
   * Split characters of an element.
   *
   * @param  {element}  $element      The target element.
   * @param  {bool}     preserveHTML  The preserve html flag.
   * @param  {string}   opening       The opening tag.
   * @param  {string}   closing       The closing tag.
   * @return {void}
   */
  splitCharacters: (
    $element,
    preserveHTML = true,
    opening = "<span>",
    closing = "</span>"
  ) => {
    // Return the split characters
    return splitCharacters($element, preserveHTML, opening, closing);
  },
  /**
   * Split characters of an element.
   *
   * @param  {element/string}  input      The target element.
   * @param  {bool}     preserveHTML  The preserve html flag.
   * @param  {string}   opening       The opening tag.
   * @param  {string}   closing       The closing tag.
   * @return {void}
   */
  splitWords: (
    input,
    preserveHTML = true,
    opening = "<span>",
    closing = "</span>"
  ) => {
    // Return the split characters
    return splitWords(input, preserveHTML, opening, closing);
  },

  /**
   * Get the event target or an event target parent based by selector.
   *
   * @param  {element}  target     The event target.
   * @param  {string}   attribute  The event target attribute to check.
   * @param  {string}   selector   The id/class selector.
   * @return {element}             The event target selector, event target selector ancestor, false otherwise.
   */
  getTargetSelectorParent: (target, attribute, selector) => {
    // Return the event target selector, event target selector ancestor, false otherwise
    return getTargetSelectorParent(target, attribute, selector);
  },

  /**
   * Check if an event target is a target selector or a descendant of a target selector.
   *
   * @param  {element}  target     The event target.
   * @param  {string}   attribute  The event target attribute to check.
   * @param  {string}   selector   The id/class selector.
   * @return {bool}                True if event target, false otherwise.
   */
  isTargetSelector: (target, attribute, selector) => {
    // Return true if event target, false otherwise
    return isTargetSelector(target, attribute, selector);
  },

  /**
   * Scroll to an element.
   *
   * @param  {element}  $element  The element to scroll to.
   * @param  {int}      offsetX   The x axis offset.
   * @param  {int}      offsetY   The y axis offset.
   * @param  {string}   behavior  The scroll behavior.
   * @return {void}
   */
  scrollToElement: ($element, offsetX = 0, offsetY = 0, behavior = "auto") => {
    // Scroll to an element
    scrollToElement($element, offsetX, offsetY, behavior);
  },

  /**
   * Trap focus to an element.
   *
   * @param  {element}  $element  The element.
   * @return {void}
   */
  trapFocus: ($element) => {
    // Trap focus to an element
    trapFocus($element);
  },
};

// Export the module
export default helper;
