export const ArchiveKnowledgeHubTaxonomies = () => {
  const $taxonomies = document.querySelector(
    ".archive-knowledge-hub-taxonomies"
  );
  if ($taxonomies) {
    const $taxonomiesContents = $taxonomies.querySelector(
      ".archive-knowledge-hub-taxonomies__contents"
    );
    const $taxonomiesButton = $taxonomiesContents.querySelector(
      ".archive-knowledge-hub-taxonomies__button"
    );
    const $taxonomiesMenu = $taxonomiesContents.querySelector(
      ".archive-knowledge-hub-taxonomies__menu"
    );

    const $taxonomiesLinks = $taxonomiesMenu.querySelectorAll(
      ".archive-knowledge-hub-taxonomies__link"
    );

    $taxonomiesButton.addEventListener("click", (event) => {
      event.preventDefault();
      if (
        $taxonomiesMenu.classList.contains(
          "archive-knowledge-hub-taxonomies__menu--active"
        )
      ) {
        $taxonomiesMenu.classList.remove(
          "opacity-100",
          "pointer-events-auto",
          "archive-knowledge-hub-taxonomies__menu--active"
        );
        $taxonomiesMenu.classList.add("opacity-0", "pointer-events-none");
      } else {
        $taxonomiesMenu.classList.remove("opacity-0", "pointer-events-none");
        $taxonomiesMenu.classList.add(
          "opacity-100",
          "pointer-events-auto",
          "archive-knowledge-hub-taxonomies__menu--active"
        );
      }
    });
  }
};

export default ArchiveKnowledgeHubTaxonomies;
