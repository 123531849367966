export const FaqsList = () => {
  const softwareFeatures = document.querySelector(".faqs-lists");
  if (softwareFeatures) {
    const lists = softwareFeatures.querySelectorAll(".faqs-lists__list");
    lists.forEach((list) => {
      const features = list.querySelectorAll(".faqs-lists__item");
      features.forEach((feature, index) => {
        const top = feature.querySelector(".faqs-lists__item-top");
        const bottom = feature.querySelector(".faqs-lists__item-bottom");

        top.addEventListener("click", (event) => {
          event.preventDefault();
          const clickedFeature = feature;
          features.forEach((featureInner, indexInner) => {
            const topInner = featureInner.querySelector(
              ".faqs-lists__item-top"
            );
            const icon = topInner.querySelector(".faqs-lists__icon");
            const bottomInner = featureInner.querySelector(
              ".faqs-lists__item-bottom"
            );

            if (clickedFeature === featureInner) {
              featureInner.classList.remove("border-b");
              featureInner.classList.add(
                "faqs-lists__item--active",
                "bg-off-white"
              );
              topInner.classList.add("border-t-2");
              icon.classList.remove("rotate-0");
              icon.classList.add("rotate-180");
              bottomInner.classList.remove("hidden");
            } else {
              featureInner.classList.remove(
                "faqs-lists__item--active",
                "bg-off-white"
              );
              featureInner.classList.add("border-b");
              topInner.classList.remove("border-t-2");
              icon.classList.remove("rotate-180");
              icon.classList.add("rotate-0");
              bottomInner.classList.add("hidden");
            }
          });
        });
      });
    });
  }
};

export default FaqsList;
