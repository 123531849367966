import Swiper from "swiper/swiper-bundle";

export const AboutTeam = () => {
  const aboutTeam = document.querySelector(".about-team");
  if (aboutTeam) {
    const $swipers = aboutTeam.querySelectorAll(
      ".about-team__list.about-team__list--mobile .about-team__list-swiper"
    );

    $swipers.forEach(($swiper) => {
      const swiper = new Swiper($swiper, {
        direction: "horizontal",
        // loopAdditionalSlides: 5,
        spaceBetween: 12,
        slidesPerView: 1,
        slideActiveClass: "about-team__list-slide--active",
        slideBlankClass: "about-team__list-slide--blank",
        slideClass: "about-team__list-slide",
        slideDuplicateActiveClass: "about-team__list-slide-duplicate--active",
        slideDuplicateClass: "about-team__list-slide-duplicate",
        slideDuplicateNextClass: "about-team__list-slide-duplicate--next",
        slideDuplicatePrevClass: "about-team__list-slide-duplicate--prev",
        slideNextClass: "about-team__list-slide--next",
        slidePrevClass: "about-team__list-slide--prev",
        wrapperClass: "about-team__list-wrapper",
      });
    });
  }
};

export default AboutTeam;
