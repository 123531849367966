const ContactForm = () => {
  const contactForm = document.querySelector(".contact-form");
  console.log("contactForm", contactForm);
  if (contactForm) {
    const checkboxes = contactForm.querySelectorAll(".contact-form__checkbox");
    checkboxes.forEach((checkbox) => {
      const button = checkbox.querySelector(".contact-form__checkbox-button");
      const input = checkbox.querySelector(".contact-form__checkbox-input");
      button.addEventListener("click", (event) => {
        event.preventDefault();

        if (input.checked) {
          input.checked = false;
          button.classList.remove("border-electric-blue", "bg-electric-blue");
          button.classList.add("border-almost-black", "bg-white");
        } else {
          input.checked = true;
          button.classList.remove("border-almost-black", "bg-white");
          button.classList.add("border-electric-blue", "bg-electric-blue");
        }
      });
    });

    const form = contactForm.querySelector(".contact-form__form");
    const firstname = form.querySelector(".contact-form__form-firstname");
    const surname = form.querySelector(".contact-form__form-surname");
    const company = form.querySelector(".contact-form__form-company");
    const job = form.querySelector(".contact-form__form-job");
    const email = form.querySelector(".contact-form__form-email");
    const phone = form.querySelector(".contact-form__form-phone");
    const how = form.querySelector(".contact-form__form-how");
    const byPhone = form.querySelector(".contact-form__form-by-phone");
    const byEmail = form.querySelector(".contact-form__form-by-email");
    const submit = form.querySelector(".contact-form__form-submit");

    const notification = contactForm.querySelector(
      ".contact-form__notification"
    );
    const notificationText = notification.querySelector(
      ".contact-form__notification-text"
    );

    const key = document.querySelector(".recaptcha_api_site_key");

    form.addEventListener("submit", (event) => {
      event.preventDefault();
      grecaptcha.ready(function () {
        grecaptcha
          .execute(key.value, { action: "contact" })
          .then(function (token) {
            let formData = new FormData();
            formData.append("firstname", firstname.value);
            formData.append("surname", surname.value);
            formData.append("company", company.value);
            formData.append("job", job.value);
            formData.append("email", email.value);
            formData.append("phone", phone.value);
            formData.append("how", how.value);
            formData.append("byPhone", byPhone.checked);
            formData.append("byEmail", byEmail.checked);
            formData.append("token", token);

            fetch("/wp-json/hwc/v1/form", {
              method: "post",
              body: formData,
            })
              .then((response) => response.json())
              .then((json) => {
                if (json.status === "success") {
                  notificationText.innerText =
                    "Thank you for your inquiry, We will contact you soon.";
                } else {
                  notificationText.innerText =
                    "Sorry but an error has happened, please try again later.";
                }
                form.classList.add("hidden");
                notification.classList.remove("hidden");
              });
          });
      });
    });
  }
};

export default ContactForm;
