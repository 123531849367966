import helpers from "../../components/helpers/main";

export const SoftwareHero = () => {
  const rotation = 360;
  const step = 4;
  const numberToRotate = 2;
  const rotatingTiles = [];
  const rotatingFinalTiles = [];
  const rotatingFixedTiles = [];
  let tiles = [];
  let finalTiles = [];
  let tilesMobile = [];
  let fixedTilesMobile = [];
  let finalTilesMobile = [];

  const softwareHero = document.querySelector(".software-hero");
  if (softwareHero) {
    helpers.addActiveOnScroll(softwareHero);

    const heroSvg = softwareHero.querySelector(".software-hero__svg");
    const mobileHeroSvg = softwareHero.querySelector(
      ".software-hero__mobile-svg"
    );
    if (heroSvg && mobileHeroSvg) {
      const activeLine = heroSvg.querySelector(".active-line");
      const activeLinePath = activeLine.querySelector(".active-line__path");

      const mobileActiveLine = mobileHeroSvg.querySelector(
        ".mobile-active-line"
      );
      const mobileActiveLinePath = mobileActiveLine.querySelector(
        ".mobile-active-line__path"
      );

      let maxTime = 0;

      const selectRandomTile = (tilesArray, rotatingArray) => {
        const index = Math.floor(Math.random() * (tilesArray.length - 0) + 0);
        const placeholder = tilesArray.splice(index, 1);
        rotatingArray.push(placeholder[0]);
      };

      const randomIntFromInterval = (min, max) => {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
      };

      const rotateTile = (tile, min, max, delay = 0) => {
        const tileBox = tile.getBBox();
        const tileX = tileBox.x + tileBox.width / 2;
        const tileY = tileBox.y + tileBox.height / 2;
        const steps = randomIntFromInterval(min, max);

        if (steps > maxTime) {
          maxTime = steps;
        }

        tile.setAttribute(
          "transform",
          `rotate(${steps * (rotation / step)} ${tileX} ${tileY})`
        );

        const element = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "animateTransform"
        );
        element.setAttribute("attributeName", "transform");
        element.setAttribute("attributeType", "XML");
        element.setAttribute("type", "rotate");
        element.setAttribute(
          "from",
          `${steps * (rotation / step)} ${tileX} ${tileY}`
        );
        element.setAttribute("to", `0 ${tileX} ${tileY}`);
        element.setAttribute("dur", `${steps}s`);
        element.setAttribute("repeatCount", "1");
        element.setAttribute("fill", "freeze");

        setTimeout(() => {
          tile.appendChild(element);
          const animation = tile.querySelector("animateTransform");
          animation.beginElement();
        }, delay);
      };

      tiles = Array.from(heroSvg.querySelectorAll(".tile"));
      finalTiles = Array.from(heroSvg.querySelectorAll(".tile_final "));

      tilesMobile = Array.from(mobileHeroSvg.querySelectorAll(".tile"));
      fixedTilesMobile = Array.from(
        mobileHeroSvg.querySelectorAll(".tile_fixed")
      );
      finalTilesMobile = Array.from(
        mobileHeroSvg.querySelectorAll(".tile_final ")
      );

      const startSelection = (tilesArray, rotatingArray) => {
        for (let i = 0; i < numberToRotate; i++) {
          if (tilesArray.length > 0) {
            selectRandomTile(tilesArray, rotatingArray);
          } else {
            break;
          }
        }
      };

      startSelection(tiles, rotatingTiles);
      startSelection(finalTiles, rotatingFinalTiles);
      startSelection(tilesMobile, rotatingTiles);
      startSelection(fixedTilesMobile, rotatingFixedTiles);
      startSelection(finalTilesMobile, rotatingFinalTiles);

      rotatingTiles.forEach((tile, tileIndex) => {
        rotateTile(tile, 2, 2);
      });

      rotatingFixedTiles.forEach((tile, tileIndex) => {
        rotateTile(tile, 1, 1);
      });

      rotatingFinalTiles.forEach((tile, tileIndex) => {
        rotateTile(tile, 1, 1, 2250);
      });

      setTimeout(() => {
        activeLinePath.classList.add("active");
      }, maxTime * 1500);

      setTimeout(() => {
        mobileActiveLinePath.classList.add("active");
      }, maxTime * 1000);
    }
  }
};

export default SoftwareHero;
