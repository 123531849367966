import helpers from "../../components/helpers/main";

export const HomepageIntro = () => {
  const homepageIntro = document.querySelector(".homepage-intro");
  if (homepageIntro) {
    // Change the background when scrolled into view
    // Title animation
    const homepageIntroTitle = homepageIntro.querySelectorAll(
      ".homepage-intro__title"
    );
    helpers.animateLetters(homepageIntroTitle);
    helpers.addActiveOnScroll(homepageIntro, true);
  }
};

export default HomepageIntro;
