export const SoftwareFeatures = () => {
  const softwareFeatures = document.querySelector(".software-features");
  if (softwareFeatures) {
    const $desktop = softwareFeatures.querySelector(
      ".software-features__desktop"
    );
    if ($desktop) {
      const $features = $desktop.querySelectorAll(
        ".software-features__list .software-features__item"
      );
      // const $images = $desktop.querySelectorAll(
      //   ".software-features__images .software-features__image"
      // );
      $features.forEach((feature, index) => {
        const $top = feature.querySelector(".software-features__item-top");
        const $bottom = feature.querySelector(
          ".software-features__item-bottom"
        );

        $top.addEventListener("click", (event) => {
          event.preventDefault();
          const clickedFeature = feature;
          const featureIndex = clickedFeature.dataset.index;
          $features.forEach((featureInner, indexInner) => {
            const $topInner = featureInner.querySelector(
              ".software-features__item-top"
            );
            const $icon = $topInner.querySelector(".software-features__icon");
            const $bottomInner = featureInner.querySelector(
              ".software-features__item-bottom"
            );

            if (clickedFeature === featureInner) {
              featureInner.classList.remove("border-b");
              featureInner.classList.add(
                "software-features__item--active",
                "bg-off-white"
              );
              $topInner.classList.add("border-t-2");
              $icon.classList.remove("rotate-0");
              $icon.classList.add("rotate-180");
              $bottomInner.classList.remove("hidden");
            } else {
              featureInner.classList.remove(
                "software-features__item--active",
                "bg-off-white"
              );
              featureInner.classList.add("border-b");
              $topInner.classList.remove("border-t-2");
              $icon.classList.remove("rotate-180");
              $icon.classList.add("rotate-0");
              $bottomInner.classList.add("hidden");
            }
          });
          // $images.forEach(($image) => {
          //   if (featureIndex === $image.dataset.index) {
          //     $image.classList.remove("hidden");
          //   } else {
          //     $image.classList.add("hidden");
          //   }
          // });
        });
      });
    }

    const $mobile = softwareFeatures.querySelector(
      ".software-features__mobile"
    );

    if ($mobile) {
      const $features = $mobile.querySelectorAll(
        ".software-features__list .software-features__item"
      );
      $features.forEach(($feature, index) => {
        const $top = $feature.querySelector(".software-features__item-top");
        const $icon = $top.querySelector(".software-features__icon");
        const $bottom = $feature.querySelector(
          ".software-features__item-bottom"
        );

        $top.addEventListener("click", (event) => {
          event.preventDefault();
          if ($bottom.classList.contains("hidden")) {
            $feature.classList.remove("border-b");
            $feature.classList.add(
              "software-features__item--active",
              "bg-off-white"
            );
            $top.classList.add("border-t-2", "px-6");
            $icon.classList.remove("rotate-0");
            $icon.classList.add("rotate-180");
            $bottom.classList.remove("hidden");
          } else {
            $feature.classList.remove(
              "software-features__item--active",
              "bg-off-white"
            );
            $feature.classList.add("border-b");
            $top.classList.remove("border-t-2", "px-6");
            $icon.classList.remove("rotate-180");
            $icon.classList.add("rotate-0");
            $bottom.classList.add("hidden");
          }
        });
      });
    }
  }
};

export default SoftwareFeatures;
