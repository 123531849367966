/**
 * Scroll to an element.
 *
 * @param  {element}  $element  The element to scroll to.
 * @param  {int}      offsetX   The x axis offset.
 * @param  {int}      offsetY   The y axis offset.
 * @param  {string}   behavior  The scroll behavior.
 * @return {void}
 */
export const scrollToElement = ($element, offsetX = 0, offsetY = 0, behavior = "auto") => {
  // Check if the element exist
  if ($element) {
    // Set the data
    const left = $element.offsetLeft + offsetX;
    const top = $element.offsetTop + offsetY;

    // Scroll the window
    window.scrollTo({
      behavior: behavior,
      left: left,
      top: top,
    });
  }
};


// Export the module
export default scrollToElement;
