import helpers from "../../components/helpers/main";
import Swiper from "swiper/swiper-bundle";

export const CaseStudiesOther = () => {
  const otherPosts = document.querySelector(".case-studies-other");
  if (otherPosts) {
    const otherPostsTitles = otherPosts.querySelectorAll(
      ".case-studies-other__title"
    );
    otherPostsTitles.forEach((otherPostsTitle, index) => {
      const wordsArray = otherPostsTitle.textContent.split(" ");
      let wordsString = "";

      wordsArray.forEach((string, stringIndex) => {
        const word = helpers.splitCharacters(
          string,
          true,
          `<span class="letter">`,
          `</span>`
        );
        wordsString =
          wordsString +
          (stringIndex === 0
            ? `<span class="inline-block">${word}</span>`
            : `<span>&nbsp;</span><span class="inline-block">${word}</span>`);
      });

      otherPostsTitle.innerHTML = wordsString;
    });

    otherPostsTitles.forEach((otherPostsTitle, index) => {
      const spans = otherPostsTitle.querySelectorAll("span.letter");
      spans.forEach((span, spanIndex) => {
        span.style.transitionDelay = `${spanIndex * 20}ms`;
      });
    });

    helpers.addActiveOnScroll(otherPosts);

    const $swipers = otherPosts.querySelectorAll(
      ".case-studies-other__posts.case-studies-other__posts--mobile .case-studies-other__posts-swiper"
    );

    $swipers.forEach(($swiper) => {
      const swiper = new Swiper($swiper, {
        direction: "horizontal",
        // loopAdditionalSlides: 5,
        spaceBetween: 12,
        slidesPerView: 1,
        slideActiveClass: "case-studies-other__posts-slide--active",
        slideBlankClass: "case-studies-other__posts-slide--blank",
        slideClass: "case-studies-other__posts-slide",
        slideDuplicateActiveClass:
          "case-studies-other__posts-slide-duplicate--active",
        slideDuplicateClass: "case-studies-other__posts-slide-duplicate",
        slideDuplicateNextClass:
          "case-studies-other__posts-slide-duplicate--next",
        slideDuplicatePrevClass:
          "case-studies-other__posts-slide-duplicate--prev",
        slideNextClass: "case-studies-other__posts-slide--next",
        slidePrevClass: "case-studies-other__posts-slide--prev",
        wrapperClass: "case-studies-other__posts-wrapper",
      });
    });
  }
};

export default CaseStudiesOther;
