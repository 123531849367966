import helpers from "../../components/helpers/main";

export const HomepageHero = () => {
  const rotation = 360;
  const step = 4;
  const numberToRotate = 2;
  const rotatingTiles = [];
  const rotatingFinalTiles = [];
  const rotatingFixedTiles = [];
  let tiles = [];
  let finalTiles = [];
  let tilesMobile = [];
  let fixedTilesMobile = [];
  let finalTilesMobile = [];

  const homepageHero = document.querySelector(".homepage-hero");
  if (homepageHero) {
    const homepageSoftwareTitles = homepageHero.querySelectorAll(
      ".homepage-hero__contents .homepage-hero__softwares .homepage-hero__software .homepage-hero__title"
    );
    homepageSoftwareTitles.forEach((homepageSoftwareTitle, index) => {
      const wordsArray = homepageSoftwareTitle.textContent.split(" ");
      let wordsString = "";

      wordsArray.forEach((string, stringIndex) => {
        const word = helpers.splitCharacters(
          string,
          true,
          `<span class="letter">`,
          `</span>`
        );
        wordsString =
          wordsString +
          (stringIndex === 0
            ? `<span class="inline-block">${word}</span>`
            : `<span>&nbsp;</span><span class="inline-block">${word}</span>`);
      });

      homepageSoftwareTitle.innerHTML = wordsString;
    });

    homepageSoftwareTitles.forEach((homepageSoftwareTitle, index) => {
      const spans = homepageSoftwareTitle.querySelectorAll("span.letter");
      spans.forEach((span, spanIndex) => {
        span.style.transitionDelay = `${spanIndex * 20}ms`;
      });
    });

    helpers.addActiveOnScroll(homepageHero);

    // SVG LOGIC

    const heroSvg = homepageHero.querySelector(".homepage-hero__svg");
    const greenLine = heroSvg.querySelector(".green-line");
    const greenLinePath = greenLine.querySelector(".green-line__path");

    const mobileHeroSvg = homepageHero.querySelector(
      ".homepage-hero__mobile-svg"
    );
    const mobileGreenLine = mobileHeroSvg.querySelector(".mobile-green-line");
    const mobileGreenLinePath = mobileGreenLine.querySelector(
      ".mobile-green-line__path"
    );

    let maxTime = 0;

    const selectRandomTile = (tilesArray, rotatingArray) => {
      const index = Math.floor(Math.random() * (tilesArray.length - 0) + 0);
      const placeholder = tilesArray.splice(index, 1);
      rotatingArray.push(placeholder[0]);
    };

    const randomIntFromInterval = (min, max) => {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const rotateTile = (tile, min, max, delay = 0) => {
      const tileBox = tile.getBBox();
      const tileX = tileBox.x + tileBox.width / 2;
      const tileY = tileBox.y + tileBox.height / 2;
      const steps = randomIntFromInterval(min, max);

      if (steps > maxTime) {
        maxTime = steps;
      }

      tile.setAttribute(
        "transform",
        `rotate(${steps * (rotation / step)} ${tileX} ${tileY})`
      );

      const element = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "animateTransform"
      );
      element.setAttribute("attributeName", "transform");
      element.setAttribute("attributeType", "XML");
      element.setAttribute("type", "rotate");
      element.setAttribute(
        "from",
        `${steps * (rotation / step)} ${tileX} ${tileY}`
      );
      element.setAttribute("to", `0 ${tileX} ${tileY}`);
      element.setAttribute("dur", `${steps}s`);
      element.setAttribute("repeatCount", "1");
      element.setAttribute("fill", "freeze");

      setTimeout(() => {
        tile.appendChild(element);
        const animation = tile.querySelector("animateTransform");
        animation.beginElement();
      }, delay);
    };

    tiles = Array.from(heroSvg.querySelectorAll(".tile"));
    finalTiles = Array.from(heroSvg.querySelectorAll(".tile_final "));

    tilesMobile = Array.from(mobileHeroSvg.querySelectorAll(".tile"));
    fixedTilesMobile = Array.from(
      mobileHeroSvg.querySelectorAll(".tile_fixed")
    );
    finalTilesMobile = Array.from(
      mobileHeroSvg.querySelectorAll(".tile_final ")
    );

    const startSelection = (tilesArray, rotatingArray) => {
      for (let i = 0; i < numberToRotate; i++) {
        if (tilesArray.length > 0) {
          selectRandomTile(tilesArray, rotatingArray);
        } else {
          break;
        }
      }
    };

    startSelection(tiles, rotatingTiles);
    startSelection(finalTiles, rotatingFinalTiles);
    startSelection(tilesMobile, rotatingTiles);
    startSelection(fixedTilesMobile, rotatingFixedTiles);
    startSelection(finalTilesMobile, rotatingFinalTiles);

    rotatingTiles.forEach((tile, tileIndex) => {
      rotateTile(tile, 2, 2);
    });

    rotatingFixedTiles.forEach((tile, tileIndex) => {
      rotateTile(tile, 1, 1);
    });

    rotatingFinalTiles.forEach((tile, tileIndex) => {
      rotateTile(tile, 1, 1, 2250);
    });

    setTimeout(() => {
      greenLinePath.classList.add("active");
      mobileGreenLinePath.classList.add("active");
    }, maxTime * 1000);
  }
};

export default HomepageHero;
