import helpers from "../../components/helpers/main";

export const HomepageFaqs = () => {
  const homepageFaqs = document.querySelector(".homepage-faqs");
  if (homepageFaqs) {
    const homepageFaqTitles = homepageFaqs.querySelectorAll(
      ".homepage-faqs__title"
    );
    helpers.animateLetters(homepageFaqTitles);

    helpers.addActiveOnScroll(homepageFaqs);
  }
};

export default HomepageFaqs;
