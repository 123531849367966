import lottie from "lottie-web";
import LogoAnimation from "../bodymovin/logo";

export const Header = () => {
  const body = document.querySelector("body");
  const header = document.querySelector(".site-header");

  if (header) {
    const navbar = header.querySelector(".navbar");
    const desktopNavbar = navbar.querySelector(".navbar-desktop");
    const mobileNavbar = navbar.querySelector(".navbar-mobile");

    // Desktop Navbar
    if (desktopNavbar) {
      const menu = desktopNavbar.querySelector(".navbar-desktop__menu");
      const productsButton = desktopNavbar.querySelector(
        ".navbar-desktop__link--products"
      );
      const lists = document.querySelectorAll(".navbar-desktop__menu-list");

      const toggleNav = () => {
        if (navbar.classList.contains("active")) {
          closeNav();
        } else {
          openNav();
        }
      };

      const openNav = () => {
        body.classList.add("overflow-hidden");
        navbar.classList.add("active");
      };

      const closeNav = () => {
        body.classList.remove("overflow-hidden");
        navbar.classList.remove("active");
        lists.forEach((list) => {
          if (list.dataset.software === "all") {
            list.classList.remove("hidden");
          } else {
            list.classList.add("hidden");
          }
        });
        productButtons.forEach((productInner, productInnerIndex) => {
          productInner.classList.remove("bg-white");
        });
      };

      productsButton.addEventListener("click", (event) => {
        event.preventDefault();
        toggleNav();
      });

      const productButtons = document.querySelectorAll(
        ".navbar-desktop__menu-product"
      );

      productButtons.forEach((product, prouctIndex) => {
        product.addEventListener("mouseenter", (event) => {
          event.preventDefault();
          const software = product.dataset.software;

          productButtons.forEach((productInner, productInnerIndex) => {
            if (productInner === event.target) {
              productInner.classList.add("bg-white");
            } else {
              productInner.classList.remove("bg-white");
            }
          });

          lists.forEach((list) => {
            if (list.dataset.software === software) {
              list.classList.remove("hidden");
            } else {
              list.classList.add("hidden");
            }
          });
        });
      });

      const menuClose = menu.querySelector(".navbar-desktop__menu-close");
      menuClose.addEventListener("click", (event) => {
        event.preventDefault();
        toggleNav();
      });

      const demoLinks = desktopNavbar.querySelectorAll(
        `a[href="#request-demo"]`
      );
      demoLinks.forEach((demoLink) => {
        demoLink.addEventListener("click", (event) => {
          closeNav();
        });
      });

      const desktopLogoContainer = document.querySelector(
        ".navbar-desktop-logo-animation"
      );
      const desktopLogoJson = JSON.stringify(LogoAnimation, null, 2);
      const desktopLogoBlob = new Blob([desktopLogoJson], {
        type: "application/json",
      });
      const desktopLogoUrl = URL.createObjectURL(desktopLogoBlob);

      const desktopAnimatedLogo = lottie.loadAnimation({
        container: desktopLogoContainer,
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: desktopLogoUrl,
      });

      let playedAnimation = false;

      window.addEventListener("scroll", (event) => {
        if (!playedAnimation) {
          desktopAnimatedLogo.play();
          playedAnimation = true;
        }
      });
    }

    // Mobile Nav
    if (mobileNavbar) {
      const mobileMenuButton = mobileNavbar.querySelector(
        ".navbar-mobile__menu-button"
      );
      const mobileOpenSvg = mobileMenuButton.querySelector(
        ".navbar-mobile__menu-open"
      );
      const mobileCloseSvg = mobileMenuButton.querySelector(
        ".navbar-mobile__menu-close"
      );

      const toggleNav = () => {
        if (navbar.classList.contains("active")) {
          closeNav();
        } else {
          openNav();
        }
      };

      const openNav = () => {
        navbar.classList.add("active");
        body.classList.add("overflow-hidden");
        mobileOpenSvg.classList.add("hidden");
        mobileCloseSvg.classList.remove("hidden");
      };

      const closeNav = () => {
        navbar.classList.remove("active");
        body.classList.remove("overflow-hidden");
        mobileOpenSvg.classList.remove("hidden");
        mobileCloseSvg.classList.add("hidden");
      };

      mobileMenuButton.addEventListener("click", (event) => {
        event.preventDefault();
        toggleNav();
      });

      const demoLinks = mobileNavbar.querySelectorAll(
        `a[href="#request-demo"]`
      );
      demoLinks.forEach((demoLink) => {
        demoLink.addEventListener("click", (event) => {
          closeNav();
        });
      });

      const mobileLogoContainer = document.querySelector(
        ".navbar-mobile-logo-animation"
      );
      const mobileLogoJson = JSON.stringify(LogoAnimation, null, 2);
      const mobileLogoBlob = new Blob([mobileLogoJson], {
        type: "application/json",
      });
      const mobileLogoUrl = URL.createObjectURL(mobileLogoBlob);

      const mobileAnimatedLogo = lottie.loadAnimation({
        container: mobileLogoContainer,
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: mobileLogoUrl,
      });

      let playedAnimation = false;

      window.addEventListener("scroll", (event) => {
        if (!playedAnimation) {
          mobileAnimatedLogo.play();
          playedAnimation = true;
        }
      });
    }
  }
};

export default Header;
