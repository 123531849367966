import helpers from "../../components/helpers/main";

export const HomepageSoftware = () => {
  const homepageSoftware = document.querySelector(".homepage-software");
  if (homepageSoftware) {
    // Title animation
    const homepageSoftwareTitle = homepageSoftware.querySelectorAll(
      ".homepage-software__title"
    );
    helpers.animateLetters(homepageSoftwareTitle);
    helpers.addActiveOnScroll(homepageSoftware);

    // Dropdowns
    const $softwareItems = homepageSoftware.querySelectorAll(
      ".homepage-software__item"
    );
    $softwareItems.forEach(($softwareItem) => {
      const $itemTypes = $softwareItem.querySelectorAll(
        ".homepage-software__types .homepage-software__type"
      );
      $itemTypes.forEach(($itemType) => {
        const $typeTop = $itemType.querySelector(
          ".homepage-software__type-top"
        );

        $typeTop.addEventListener("click", (event) => {
          event.preventDefault();
          const $chevron = $itemType.querySelector(".homepage-software-chevron");
          $chevron.classList.toggle("rotate-180");
          if ($itemType.classList.contains("homepage-software__type--active")) {
            $itemType.classList.remove("homepage-software__type--active");
          } else {
            $itemType.classList.add("homepage-software__type--active");
          }
        });
      });
    });
  }
};

export default HomepageSoftware;
