import { clickModalCloseTriggerEventHandler } from "./click-modal-close-trigger.js";
import { helper } from "../../helpers/main.js";

/**
 * Event handler to open a modal.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const clickModalOpenTriggerEventHandler = (event) => {
  // Set the elements
  const $element = event.target;

  // Check if the element is the modal open trigger or modal open trigger descendant
  if (helper.isTargetSelector($element, "class", "js-modal-open")) {
    // Set the elements
    const $open = helper.getTargetSelectorParent(
      $element,
      "class",
      "js-modal-open"
    );

    // Check if the modal open trigger exists
    if ($open) {
      // Prevent the default action
      event.preventDefault();

      // Set the elements
      const $modal = document.querySelector($open.getAttribute("href"));

      // Check if the modal exists
      if ($modal) {
        // Set the elements
        const $closes = $modal.querySelectorAll(".js-modal-close");

        if ($closes) {
          // Set the body classes
          document.body.classList.add("overflow-hidden");

          // Set the modal classes
          $modal.classList.remove("hidden");

          // Trap focus to the modal
          helper.trapFocus($modal);

          // Cycle through all of the close triggers
          $closes.forEach(($close) => {
            // Set the close data
            $close.data = {
              open: $open,
            };

            // Add a click event listener to the modal close trigger
            $close.addEventListener(
              "click",
              clickModalCloseTriggerEventHandler
            );
          });
        }
      }
    }
  }
};

// Export the module
export default clickModalOpenTriggerEventHandler;
