import helpers from "../../components/helpers/main";

export const HomepageDemo = () => {
  const homepageDemo = document.querySelector(".homepage-demo");
  if (homepageDemo) {
    const homepageDemoTitles = homepageDemo.querySelectorAll(
      ".footer-demo__title"
    );
    homepageDemoTitles.forEach((homepageDemoTitle, index) => {
      const wordsArray = homepageDemoTitle.textContent.split(" ");
      let wordsString = "";

      wordsArray.forEach((string, stringIndex) => {
        const word = helpers.splitCharacters(
          string,
          true,
          `<span class="letter">`,
          `</span>`
        );
        wordsString =
          wordsString +
          (stringIndex === 0
            ? `<span class="inline-block">${word}</span>`
            : `<span>&nbsp;</span><span class="inline-block">${word}</span>`);
      });

      homepageDemoTitle.innerHTML = wordsString;
    });

    homepageDemoTitles.forEach((homepageDemoTitle, index) => {
      const spans = homepageDemoTitle.querySelectorAll("span.letter");
      spans.forEach((span, spanIndex) => {
        span.style.transitionDelay = `${spanIndex * 20}ms`;
      });
    });

    helpers.addActiveOnScroll(homepageDemo);

    const checkboxes = homepageDemo.querySelectorAll(
      ".footer-demo__checkbox"
    );
    checkboxes.forEach((checkbox) => {
      const button = checkbox.querySelector(".footer-demo__checkbox-button");
      const input = checkbox.querySelector(".footer-demo__checkbox-input");
      button.addEventListener("click", (event) => {
        event.preventDefault();

        if (input.checked) {
          input.checked = false;
          button.classList.remove("border-electric-blue", "bg-electric-blue");
          button.classList.add("border-almost-black", "bg-white");
        } else {
          input.checked = true;
          button.classList.remove("border-almost-black", "bg-white");
          button.classList.add("border-electric-blue", "bg-electric-blue");
        }
      });
    });
  }
};

export default HomepageDemo;
