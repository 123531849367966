/**
 * Handle the update security form.
 *
 * @return {void}
 */
export const updateSecurityFormHandler = () => {
  /**
   * Event handler to process the form submission.
   *
   * @param  {object}  event  The event object.
   * @return {void}
   */
  const submitFormEventHandler = (event) => {
    // Prevent the default action
    event.preventDefault();

    // Set the form elements
    const $form = event.currentTarget;
    const $csrf = document.querySelector('meta[name="csrf"]');
    const $honeypot = $form.querySelector("input[name=hp]");
    const $email = $form.querySelector("input[name=email]");
    const $newPassword = $form.querySelector("input[name=new_password]");
    const $currentPassword = $form.querySelector(
      "input[name=current_password]"
    );
    const $submit = $form.querySelector("button[type=submit]");

    // Set the default submit text
    const submitText = $submit.textContent;

    // Disable the submit and change the text
    $submit.setAttribute("disabled", "disabled");
    $submit.classList.add("cursor-not-allowed");
    $submit.innerHTML = "Loading...";

    // Create a fetch post request
    fetch($form.getAttribute("action"), {
      method: "POST",
      body: JSON.stringify({
        csrf: $csrf.getAttribute("content"),
        honeypot: $honeypot.checked,
        email: $email.value,
        new_password: $newPassword.value,
        current_password: $currentPassword.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        // Check a response error exists
        if (response.error) {
          // Display an alert
          alert(response.error_message);
        } else {
          // Display an alert
          alert(response.success_message);
        }

        // Enable the submit and restore the original text
        $submit.removeAttribute("disabled");
        $submit.classList.remove("cursor-not-allowed");
        $submit.innerHTML = submitText;
      })
      .catch((response) => {
        // Display an alert
        alert("Something went wrong, please try again later.");

        // Enable the submit and restore the original text
        $submit.removeAttribute("disabled");
        $submit.classList.remove("cursor-not-allowed");
        $submit.innerHTML = submitText;
      });
  };

  // Set the form
  const $form = document.querySelector(".js-update-security-form");

  // Check if the form exists
  if ($form) {
    // Add a submit event handler to the form
    $form.addEventListener("submit", submitFormEventHandler);
  }
};

// Export the update security form handler
export default updateSecurityFormHandler;
