/**
 * Check if an event target is a target selector or a descendant of a target selector.
 *
 * @param  {element}  target     The event target.
 * @param  {string}   attribute  The event target attribute to check.
 * @param  {string}   selector   The id/class selector.
 * @return {bool}                True if event target, false otherwise.
 */
export const isTargetSelector = (target, attribute, selector) => {
  // Check if the target is an element node
  if (target.nodeType !== Node.ELEMENT_NODE) {
    // Return no target selector
    return false;
  }

  // Start a switch statement for the attribute
  switch (attribute) {
    // Class
    case "class":
      // Return true if event target, false otherwise
      return (
        target.classList.contains(selector) || target.closest(`.${selector}`)
      );

    // Id
    case "id":
      // Return true if event target, false otherwise
      return target.id === selector || target.closest(`#${selector}`);

    // Default
    default:
      // Return no target selector
      return false;
  }
};

// Export the module
export default isTargetSelector;
