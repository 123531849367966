import { app } from "./components/app/main.js";
import { forms } from "./components/forms/main.js";
import { modal } from "./components/modal/main.js";

// Import Homepage Sections
import Header from "./sections/header";

// Import Homepage Sections
import HomepageAbout from "./sections/homepage/about";
import HomepageHero from "./sections/homepage/hero";
import HomepageSoftware from "./sections/homepage/software";
import HomepageDemo from "./sections/homepage/demo";
import HomepageFaqs from "./sections/homepage/faqs";
import HomepageIntro from "./sections/homepage/intro";
import HomepagePoints from "./sections/homepage/points";
import HomepageStudies from "./sections/homepage/studies";
import HomepageKnowledge from "./sections/homepage/knowledge";

// Import Software Sections
import SoftwareHero from "./sections/software/hero";
import SoftwareFeatures from "./sections/software/features";
import SoftwareSpecs from "./sections/software/specification";
import SoftwareStudies from "./sections/software/studies";
import SoftwareKnowledge from "./sections/software/knowledge";
import SoftwareMenu from "./sections/software/menu";

// Import Case Studies Sections
import CaseStudiesOther from "./sections/case-studies/other-posts";

// Import knowledgehub Sections
import KnowledgeHubOther from "./sections/knowledge-hub/other-posts";

// Import Case Studies Archive Sections
import ArchiveCaseStudiesTaxonomies from "./sections/archive/case-studies/taxonomies";

// Import Knowledge Hub Archive Sections
import ArchiveKnowledgeHubTaxonomies from "./sections/archive/knowledge-hub/taxonomies";

// Import FAQs Sections
import FaqsList from "./sections/faqs/list";

// Import About Sections
import AboutTeam from "./sections/about/team";

// Import Homepage Sections
import Footer from "./sections/footer";
import FooterDemo from "./sections/footer/demo";

import ContactForm from "./sections/contact/contact";

app();
forms();
modal();

Header();

HomepageAbout();
HomepageHero();
HomepageDemo();
HomepageIntro();
HomepageFaqs();
HomepageSoftware();
HomepagePoints();
HomepageStudies();
HomepageKnowledge();

SoftwareHero();
SoftwareFeatures();
SoftwareKnowledge();
SoftwareSpecs();
SoftwareStudies();
SoftwareMenu();

CaseStudiesOther();
KnowledgeHubOther();

ArchiveCaseStudiesTaxonomies();
ArchiveKnowledgeHubTaxonomies();

FaqsList();

AboutTeam();

Footer();
FooterDemo();

ContactForm();
