import helpers from "../../components/helpers/main";
import Swiper from "swiper/swiper-bundle";

export const HomepageStudies = () => {
  const homepageStudies = document.querySelector(".homepage-studies");
  if (homepageStudies) {
    helpers.addActiveOnScroll(homepageStudies);

    const studies = homepageStudies.querySelectorAll(
      ".homepage-studies__studies .homepage-studies__study"
    );
    const buttons = homepageStudies.querySelectorAll(
      ".homepage-studies__buttons .homepage-studies__button"
    );

    const $swipers = homepageStudies.querySelectorAll(
      ".homepage-studies__studies.homepage-studies__studies--mobile .homepage-studies__swiper"
    );

    $swipers.forEach(($swiper) => {
      const swiper = new Swiper($swiper, {
        direction: "horizontal",
        // loopAdditionalSlides: 5,
        spaceBetween: 12,
        slidesPerView: 1,
        slideActiveClass: "homepage-studies__slide--active",
        slideBlankClass: "homepage-studies__slide--blank",
        slideClass: "homepage-studies__slide",
        slideDuplicateActiveClass: "homepage-studies__slide-duplicate--active",
        slideDuplicateClass: "homepage-studies__slide-duplicate",
        slideDuplicateNextClass: "homepage-studies__slide-duplicate--next",
        slideDuplicatePrevClass: "homepage-studies__slide-duplicate--prev",
        slideNextClass: "homepage-studies__slide--next",
        slidePrevClass: "homepage-studies__slide--prev",
        wrapperClass: "homepage-studies__wrapper",
      });
    });

    buttons.forEach((button) => {
      button.addEventListener("click", (event) => {
        event.preventDefault();
        const clickedSoftware = event.target.dataset.software;
        buttons.forEach((buttonInner) => {
          if (buttonInner.dataset.software === clickedSoftware) {
            buttonInner.classList.add("homepage-studies__button--active");
          } else {
            buttonInner.classList.remove("homepage-studies__button--active");
          }
        });
        studies.forEach((study) => {
          if (study.dataset.software === clickedSoftware) {
            study.classList.remove("hidden");
          } else {
            study.classList.add("hidden");
          }
        });
        $swipers.forEach(($swiper) => {
          if ($swiper.dataset.software === clickedSoftware) {
            $swiper.classList.remove("hidden");
          } else {
            $swiper.classList.add("hidden");
          }
          $swiper.swiper.update();
        });
      });
    });
  }
};

export default HomepageStudies;
