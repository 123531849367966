import helpers from "../../components/helpers/main";

export const HomepagePoints = () => {
  const homepagePoints = document.querySelector(".homepage-points");
  if (homepagePoints) {
    const homepagePointsTitles = homepagePoints.querySelectorAll(
      ".homepage-points__title"
    );
    helpers.animateLetters(homepagePointsTitles);

    helpers.addActiveOnScroll(homepagePoints);
  }
};

export default HomepagePoints;
