/**
 * Get the event target or an event target parent based by selector.
 *
 * @param  {element}  target     The event target.
 * @param  {string}   attribute  The event target attribute to check.
 * @param  {string}   selector   The id/class selector.
 * @return {element}             The event target selector, event target selector ancestor, false otherwise.
 */
export const getTargetSelectorParent = (target, attribute, selector) => {
  // Start a switch statement for the attribute
  switch (attribute) {
    // Class
    case "class":
      // Check if the event target contains the selector class
      if (target.classList.contains(selector)) {
        // Return the event target selector
        return target;
      } else {
        // Return the event target selector ancestor
        return target.closest(`.${selector}`);
      }

    // Id
    case "id":
      // Check if the event target id is the selector
      if (target.getAttribute("id") === selector) {
        // Return the event target selector
        return target;
      } else {
        // Return the event target selector ancestor
        return target.closest(`#${selector}`);
      }

    // Default
    default:
      // Return no target selector
      return false;
  }
};

// Export the module
export default getTargetSelectorParent;
