import helpers from "../../components/helpers/main";

export const HomepageAbout = () => {
  const homepageAbout = document.querySelector(".homepage-about");
  if (homepageAbout) {
    // Change the background when scrolled into view
    // Title animation
    const homepageAboutTitle = homepageAbout.querySelectorAll(
      ".homepage-about__title"
    );
    helpers.animateLetters(homepageAboutTitle);
    helpers.addActiveOnScroll(homepageAbout, true);
  }
};

export default HomepageAbout;
