export const ArchiveCaseStudiesTaxonomies = () => {
  const $taxonomies = document.querySelector(
    ".archive-case-studies-taxonomies"
  );
  if ($taxonomies) {
    const $taxonomiesContents = $taxonomies.querySelector(
      ".archive-case-studies-taxonomies__contents"
    );
    const $taxonomiesButton = $taxonomiesContents.querySelector(
      ".archive-case-studies-taxonomies__button"
    );
    const $taxonomiesMenu = $taxonomiesContents.querySelector(
      ".archive-case-studies-taxonomies__menu"
    );

    const $taxonomiesLinks = $taxonomiesMenu.querySelectorAll(
      ".archive-case-studies-taxonomies__link"
    );

    $taxonomiesButton.addEventListener("click", (event) => {
      event.preventDefault();
      if (
        $taxonomiesMenu.classList.contains(
          "archive-case-studies-taxonomies__menu--active"
        )
      ) {
        $taxonomiesMenu.classList.remove(
          "opacity-100",
          "pointer-events-auto",
          "archive-case-studies-taxonomies__menu--active"
        );
        $taxonomiesMenu.classList.add("opacity-0", "pointer-events-none");
      } else {
        $taxonomiesMenu.classList.remove("opacity-0", "pointer-events-none");
        $taxonomiesMenu.classList.add(
          "opacity-100",
          "pointer-events-auto",
          "archive-case-studies-taxonomies__menu--active"
        );
      }
    });
  }
};

export default ArchiveCaseStudiesTaxonomies;
