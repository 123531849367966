export const SoftwareSpecs = () => {
  const softwareSpec = document.querySelector(".software-specifications");
  if (softwareSpec) {
    const infos = softwareSpec.querySelectorAll(
      ".software-specifications__table-row-info"
    );

    infos.forEach((info) => {
      const icon = info.querySelector(
        ".software-specifications__table-row-icon"
      );
      const textDesktop = info.querySelector(
        ".software-specifications__table-row-text.software-specifications__table-row-text--desktop"
      );

      const container = info.closest(".software-specifications__table-row");

      const textMobile = container.querySelector(
        ".software-specifications__table-row-text.software-specifications__table-row-text--mobile"
      );
      icon.addEventListener("click", (event) => {
        event.preventDefault();
        if (
          textMobile.classList.contains("opacity-0") &&
          textMobile.classList.contains("pointer-events-none")
        ) {
          textMobile.classList.remove("opacity-0", "pointer-events-none");
          textMobile.classList.add("opacity-100", "pointer-events-auto");
        } else {
          textMobile.classList.remove("opacity-100", "pointer-events-auto");
          textMobile.classList.add("opacity-0", "pointer-events-none");
        }
      });

      const close = textMobile.querySelector(
        ".software-specifications__table-row-close"
      );
      close.addEventListener("click", (event) => {
        event.preventDefault();
        textMobile.classList.remove("opacity-100", "pointer-events-auto");
        textMobile.classList.add("opacity-0", "pointer-events-none");
      });

      icon.addEventListener("mouseenter", (event) => {
        event.preventDefault();
        textDesktop.classList.remove("desktop:opacity-0");
        textDesktop.classList.add("desktop:opacity-100");
      });
      icon.addEventListener("mouseleave", (event) => {
        event.preventDefault();
        textDesktop.classList.remove("desktop:opacity-100");
        textDesktop.classList.add("desktop:opacity-0");
      });
    });

    const other = softwareSpec.querySelector(
      ".software-specifications__others"
    );

    const otherButtonRow = softwareSpec.querySelector(
      ".software-specifications__show-row"
    );
    const otherButton = softwareSpec.querySelector(
      ".software-specifications__show"
    );

    if (otherButton) {
      otherButton.addEventListener("click", (event) => {
        event.preventDefault();
        otherButtonRow.classList.add("hidden");
        other.classList.remove("hidden");
      });
    }
  }
};

export default SoftwareSpecs;
