/**
 * Split characters of an element.
 *
 * @param  {element}  $element      The target element.
 * @param  {bool}     preserveHTML  The preserve html flag.
 * @param  {string}   opening       The opening tag.
 * @param  {string}   closing       The closing tag.
 * @return {string}                 The html, text otherwise.
 */
export const splitWords = (
  input,
  preserveHTML = true,
  opening = "<span>",
  closing = "</span>"
) => {
  let outputArray = null;

  if (input && input.nodeType) {
    // Set the data
    const text = input.textContent;

    // Return the html, text otherwise
    outputArray = text.split(" ");
  } else if (typeof input === "string" || input instanceof String) {
    outputArray = input.split(" ");
  }

  let outputString = "";
  outputArray.forEach((string, stringIndex) => {
    outputString = outputString + `${opening}${string}${closing}`;
  });

  return outputString;
};

// Export the module
export default splitWords;
