export const addActiveOnScroll = (element, first = false) => {
  const inViewCheck = (firstCheck = false) => {
    const position = element.getBoundingClientRect();

    if (position.top < window.innerHeight * 0.66) {
      element.classList.add("active");
    }
    if (firstCheck && position.bottom < window.innerHeight * 0.33) {
      element.classList.add("already-onscreen");
    }
  };
  inViewCheck(first);
  window.addEventListener("scroll", (event) => inViewCheck());
};

export default addActiveOnScroll;
