/**
 * Trap focus to an element.
 *
 * @param  {element}  $element  The element.
 * @return {void}
 */
export const trapFocus = ($element) => {
  // Set the elements
  const $focusables = $element.querySelectorAll(
    'button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [href]:not([disabled]), [tabindex]:not([tabindex="-1"])'
  );
  const $focusableFirst = $focusables[0];
  const $focusableLast = $focusables[$focusables.length - 1];

  // Set the data
  const keycodeTab = 9;

  // Focus on the element
  $element.focus();

  // Add a keydown event handler to the element
  $element.addEventListener("keydown", function (event) {
    // Start a switch event for the keycode
    switch (event.keyCode) {
      // Tab
      case keycodeTab:
        // Check if no focusable elements exist
        if ($focusables.length === 0) {
          // Prevent the default action
          event.preventDefault();

          // Force focus on the element
          $element.focus();
        } else {
          // Check if the shift key was pressed
          if (event.shiftKey) {
            // Check if the active element is the first focusable element
            if (document.activeElement === $focusableFirst) {
              // Prevent the default action
              event.preventDefault();

              // Focus on the last focusable element
              $focusableLast.focus();
            }
          } else {
            if (document.activeElement === $focusableLast) {
              // Prevent the default action
              event.preventDefault();

              // Focus on the first focusable element
              $focusableFirst.focus();
            }
          }
        }

        // Break the switch
        break;
    }
  });
};


// Export the module
export default trapFocus;
