/**
 * Initialize the application.
 *
 * @return {void}
 */
export const app = () => {
  // Replace the js disabled class
  document.documentElement.className =
    document.documentElement.className.replace('js-disabled', 'js-enabled');

  // Check if touch is supported
  if ('ontouchstart' in document.documentElement) {
    // Replace the touch disabled
    document.documentElement.className =
      document.documentElement.className.replace('touch-disabled', 'touch-enabled');
  }

  // Add a click event handler to the body
  document.addEventListener('click', (event) => {
    // Set the elements
    const $element = event.target;

    // Start a switch statement for the following true values
    switch (true) {
      // Prevent default class name
      case $element.classList.contains('js-prevent-default'):
        // Prevent the default behaviour and break the switch
        event.preventDefault();
        break;

        // Stop propagation class name
        case $element.classList.contains('js-stop-propagation'):
          // Stop propagation and break the switch
          event.stopPropagation();
          break;

        // Stop immediate propagation class name
        case $element.classList.contains('js-stop-immediate-propagation'):
          // Stop immediate propagation and break the switch
          event.stopImmediatePropagation();
          break;
    }
  });
};

// Export the module
export default app;
