import { commentFormHandler } from "./functions/comment.js";
import { editProfileFormHandler } from "./functions/edit-profile.js";
import { forgotPasswordFormHandler } from "./functions/forgot-password.js";
import { loginFormHandler } from "./functions/login.js";
import { passwordProtectedFormHandler } from "./functions/password-protected.js";
import { registerFormHandler } from "./functions/register.js";
import { resetPasswordFormHandler } from "./functions/reset-password.js";
import { updateSecurityFormHandler } from "./functions/update-security.js";

/**
 * Initialize the forms.
 *
 * @return {void}
 */
export const forms = () => {
  commentFormHandler();
  editProfileFormHandler();
  forgotPasswordFormHandler();
  loginFormHandler();
  passwordProtectedFormHandler();
  registerFormHandler();
  resetPasswordFormHandler();
  updateSecurityFormHandler();
};

// Export the module
export default forms;
