/**
 * Log hello world to the console.
 *
 * @return {void}
 */
export const helloWorld = () => {
  // Log hello world to the console
  console.log('Hello, World!');
};

// Export the module
export default helloWorld;
