/**
 * Split characters of an element.
 *
 * @param  {element}  $element      The target element.
 * @param  {bool}     preserveHTML  The preserve html flag.
 * @param  {string}   opening       The opening tag.
 * @param  {string}   closing       The closing tag.
 * @return {string}                 The html, text otherwise.
 */
export const splitCharacters = (
  input,
  preserveHTML = true,
  opening = "<span>",
  closing = "</span>"
) => {
  if (input && input.nodeType) {
    // Set the data
    const html = input.innerHTML;
    const text = input.textContent;

    // Return the html, text otherwise
    return preserveHTML
      ? html
          .replace(/(?![^<]*>)[^<]/g, (c) => `${opening}${c}${closing}`)
          .replace(/>\s</g, ">&nbsp;<")
      : text.replace(/\S/g, `${opening}$&${closing}`);
  } else if (typeof input === "string" || input instanceof String) {
    return input.replace(/\S/g, `${opening}$&${closing}`);
  }
};

// Export the module
export default splitCharacters;
